import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const SEND_CONTENT_CANDIDATE = gql `
  mutation addContentCandidate($position: String,$image: Upload,$_id:ID,$title: String, $description: String) {
    addContentCandidate(position:$position,image: $image, _id:$_id, title:$title, description:$description){
      ...${candidateFragment}
    }
  }
`
export const DELETE_CONTENT_CANDIDATE = gql `
  mutation deleteContentCandidate($id:ID) {
    deleteContentCandidate(id:$id){
      ...${candidateFragment}
    }
  }
`