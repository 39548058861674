import gql from 'graphql-tag'

export default gql`{
    _id
    title
    show
    position
    description
    image
}
`