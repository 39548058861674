import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const CONTENT_CANDIDATE_ID_QUERY = gql `
  query getContentCandidateById($_id: ID){
    getContentCandidateById(_id: $_id){
      ...${candidateFragment}
    }
  }
`
export const CONTENT_CANDIDATE_QUERY = gql `
  query getContentCandidate{
    getContentCandidate{
      ...${candidateFragment}
    }
  }
`